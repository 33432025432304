import { AbstractControl, Validators } from '@angular/forms';
import { VALIDATORS_VALUES } from '../constants/validators-values';
import { ErrorCodes } from '../constants/error-codes';

const emailRegExp: RegExp = VALIDATORS_VALUES.emailPatterns;

export function emailValidator(control: AbstractControl): { [key: string]: any } | null {
  return Validators.pattern(emailRegExp)(control) ? { [ErrorCodes.Email]: true } : null;
}

export const EMAIL_VALIDATORS = [
  Validators.maxLength(VALIDATORS_VALUES.maxLengthInputField),
  emailValidator,
];
