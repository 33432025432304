import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ErrorCodes } from '../constants/error-codes';

export function minLessThanMaxValidatorFn(
  minFieldName: string, maxFieldName: string, customErrorCode?: ErrorCodes,
): ValidatorFn {
  return (control: AbstractControl): null => {
    const errorCode = customErrorCode || ErrorCodes.MinLessThanMax;

    const minControlValue = control.get(minFieldName)?.value;
    const maxControlValue = control.get(maxFieldName)?.value;
    if (minControlValue && maxControlValue) {
      if (Number.parseFloat(minControlValue) > Number.parseFloat(maxControlValue)) {
        control.get(minFieldName)?.setErrors({ [errorCode]: true });
      } else {
        control.get(minFieldName)?.setErrors(null);
      }
    } else {
      control.get(minFieldName)?.setErrors(null);
    }
    return null;
  };
}
