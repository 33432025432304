import { FormControl } from '@angular/forms';

export const MARKETPLACE_LANDS_FILTER_SORTING = {
  name: 'sort',
  control: new FormControl(null),
  placeholder: 'common.filter.sort_by',
  items: [
    { value: '-dateListed', label: 'date_listed' },
    { value: 'endingSoon', label: 'ending_soon' },
    { value: 'price', label: 'price_asc' },
    { value: '-price', label: 'price_desc' },
    { value: 'area', label: 'area_asc' },
    { value: '-area', label: 'area_desc' },
    { value: '-viewsCount', label: 'views_count' },
    { value: '-likesCount', label: 'likes_count' },
    { value: 'oldest', label: 'oldest' },
    { value: 'name', label: 'name_asc' },
    { value: '-name', label: 'name_desc' },
  ],
};

export const NFT_LANDS_FILTER_SORTING = {
  name: 'sort',
  control: new FormControl(null),
  placeholder: 'common.filter.sort_by',
  items: [
    // { value: '-dateListed', label: 'date_listed' },
    // { value: 'endingSoon', label: 'ending_soon' },
    // { value: 'price', label: 'price_asc' },
    // { value: '-price', label: 'price_desc' },
    { value: 'area', label: 'area_asc' },
    { value: '-area', label: 'area_desc' },
    { value: '-viewsCount', label: 'views_count' },
    { value: '-likesCount', label: 'likes_count' },
    { value: 'oldest', label: 'oldest' },
    { value: 'name', label: 'name_asc' },
    { value: '-name', label: 'name_desc' },
  ],
};

export const SHARDS_LANDS_FILTER_SORTING = {
  name: 'sort',
  control: new FormControl(null),
  placeholder: 'common.filter.sort_by',
  items: [
    { value: 'area', label: 'area_asc' },
    { value: '-area', label: 'area_desc' },
    { value: '-viewsCount', label: 'views_count' },
    { value: '-likesCount', label: 'likes_count' },
    { value: 'oldest', label: 'oldest' },
    { value: 'name', label: 'name_asc' },
    { value: '-name', label: 'name_desc' },
  ],
};
