import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Country } from '../interfaces/country';
import { IHttpResponse } from '../../common/intefaces/http-response.interface';
import { COUNTRIES_API } from '../api/countries-api';

@Injectable({
  providedIn: 'root',
})
export class CountriesApiService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public getCountries$(filters?: { [key: string]: string }): Observable<IHttpResponse<Country[]>> {
    const params: { [key: string]: string } = {};
    if (filters) {
      if (filters.filter) {
        params.filter = filters.filter;
      }
    }
    return this.http.get<IHttpResponse<Country[]>>(COUNTRIES_API.countries, { params });
  }
}
