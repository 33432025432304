import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CountriesApiService } from './countries.api.service';
import { CallerService } from '../../common/services/caller.service';
import { Country } from '../interfaces/country';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(
    private countriesApiService: CountriesApiService,
    private callerService: CallerService,
  ) {
  }

  public getCountries$(filters?: { [key: string]: string }): Observable<Country[]> {
    return this.callerService.call(
      this.countriesApiService.getCountries$(filters).pipe(map(({ data }) => data)),
    );
  }
}
