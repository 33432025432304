import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Country } from '../interfaces/country';
import { CountriesService } from '../services/countries.service';

@Injectable({
  providedIn: 'root',
})
export class CountriesResolver implements Resolve<Country[]> {
  constructor(private countriesService: CountriesService) {}

  resolve(route:ActivatedRouteSnapshot): Observable<Country[]> {
    return this.countriesService.getCountries$(route.data);
  }
}
