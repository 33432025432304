import { BehaviorSubject, Observable } from 'rxjs';
import { PaginationModel } from '../models/pagination.model';

export abstract class TableDataState<T> {
  protected items$ = new BehaviorSubject<T[]>([]);
  private pagination$ = new BehaviorSubject<PaginationModel>(new PaginationModel());
  private itemsLoaded: boolean = false;

  public getItems$(): Observable<T[]> {
    return this.items$.asObservable();
  }

  public getPagination$(): Observable<PaginationModel> {
    return this.pagination$.asObservable();
  }

  public setPagination(pagination: PaginationModel): void {
    this.pagination$.next(pagination);
  }

  public setItems(items: T[]): void {
    this.items$.next(items);
    this.itemsLoaded = true;
  }

  public areItemsLoaded(): boolean {
    return this.itemsLoaded;
  }

  public pushItems(items: T[]): void {
    const newItems = [...this.items$.value];
    newItems.push(...items);
    this.items$.next(newItems);
  }
}
